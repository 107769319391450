import { Tier } from '@wix/ambassador-loyalty-v1-tier/types';

import { SimpleTier } from '../../types/domain';

export const toSimpleTiers = (tiers: Tier[]): SimpleTier[] => {
  const tiersArray = tiers.map((tier) => {
    const result: SimpleTier = {
      title: tier.tierDefinition?.name ?? '',
      description: tier.tierDefinition?.description ?? '',
      requiredPoints: tier.requiredPoints ?? 0,
      iconUrl: tier.tierDefinition?.icon?.url ?? '',
      id: tier.id,
    };
    return result;
  });

  return tiersArray;
};
