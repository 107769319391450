import { listFollowedChannels } from '@wix/ambassador-loyalty-socialmedia-v1-followed-channel/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { ChannelType } from '../../types/domain';

export async function getFollowedChannels(flowAPI: ControllerFlowAPI): Promise<ChannelType[]> {
  const { httpClient } = flowAPI;
  try {
    const response = await httpClient.request(listFollowedChannels({}));
    return response.data.followedChannels?.map((channel) => channel.channel as ChannelType) ?? [];
  } catch (error) {
    // Loyalty account doesn't exist
    if (error instanceof Error && error.message.includes('404')) {
      return [];
    }
    throw error;
  }
}
