import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

interface DecodedInstance {
  metaSiteId?: string;
  appDefId?: string;
  biToken?: string;
  instanceId?: string;
  aid?: string;
  siteOwnerId?: string;
  siteIsTemplate?: boolean;
  uid?: string;
}

export const getDecodedInstance = (flowApi: ControllerFlowAPI): DecodedInstance => {
  const signedInstance = flowApi.controllerConfig.appParams.instance;
  try {
    return JSON.parse(atob(signedInstance.split('.')[1]));
  } catch (e) {
    flowApi.reportError(e as Error);
    console.error('Failed to parse signed instance', e);
    return {};
  }
};
