import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getMyRewardsPageUrl } from './getMyRewardsPageUrl';

export async function isMyRewardsPageInstalled(flowAPI: ControllerFlowAPI) {
  const { isEditor } = flowAPI.environment;
  if (isEditor) {
    return true;
  }

  const myRewardsPageUrl = await getMyRewardsPageUrl(flowAPI);
  return !!myRewardsPageUrl;
}
