import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { Status as TiersProgramStatus, TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { Experiment } from '../constants';
import { isSocialMediaEarningRule } from './earningRules';
import { getLoyaltyAccount } from './getLoyaltyAccount';
import { getFollowedChannels } from './social-media';

export const loadUserData = async (
  flowAPI: ControllerFlowAPI,
  earningRules: LoyaltyEarningRule[],
  tiersProgramSettings: TiersProgramSettings,
) => {
  const { experiments } = flowAPI;
  const { isViewer } = flowAPI.environment;
  const enableCurrentTierStyling = experiments.enabled(Experiment.CurrentTierStyling);
  const enableSocialMedia = experiments.enabled(Experiment.SocialMedia);

  const hasSocialMediaEarningRule = earningRules.some((rule) => isSocialMediaEarningRule(rule));
  const followedChannelsPromise =
    enableSocialMedia && isViewer && hasSocialMediaEarningRule ? getFollowedChannels(flowAPI) : undefined;

  const hasActiveTiersProgram = tiersProgramSettings.status === TiersProgramStatus.ACTIVE;
  const loyaltyAccountPromise =
    enableCurrentTierStyling && hasActiveTiersProgram ? getLoyaltyAccount(flowAPI) : undefined;

  const [followedChannelsResponse, loyaltyAccount] = await Promise.all([
    followedChannelsPromise,
    loyaltyAccountPromise,
  ]);
  const currentTierId = loyaltyAccount?.tier?.id ?? undefined;
  const followedChannels = followedChannelsResponse ?? [];

  return { followedChannels, currentTierId };
};
