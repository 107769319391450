import {
  LoyaltyEarningRule,
  LoyaltyEarningRuleTypeTagType,
} from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';

import { TriggerActivityType } from '../constants';

export const isBirthdayEarningRule = (earningRule: LoyaltyEarningRule) =>
  earningRule.triggerActivityType === TriggerActivityType.ContactEntersSegment &&
  earningRule.metadata?.ruleTypeTag?.ruleType === LoyaltyEarningRuleTypeTagType.BIRTHDAY;

export const isSocialMediaEarningRule = (earningRule: LoyaltyEarningRule) =>
  earningRule.triggerActivityType === TriggerActivityType.SocialMediaChannelFollowed;
