import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { createNumberFormatter } from '../createNumberFormatter';

interface CreateBirthdayRuleDescriptionParams {
  flowAPI: ControllerFlowAPI;
  loyaltyProgram: LoyaltyProgram;
  points: number;
}

export const createBirthdayRuleDescription = ({
  loyaltyProgram,
  flowAPI,
  points,
}: CreateBirthdayRuleDescriptionParams) => {
  const { t } = flowAPI.translations;
  const formatNumber = createNumberFormatter(flowAPI);

  const customPointsName = loyaltyProgram.pointDefinition?.customName;
  const key = customPointsName ? 'app.add-birthday-modal.description-custom' : 'app.add-birthday-modal.description';

  return t(key, {
    pointsName: customPointsName,
    pointsAmount: points,
    formattedPointsAmount: formatNumber(points),
  });
};
