import { ControllerFlowAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getLocale } from './getLocale';

export function createNumberFormatter(flowAPI: ControllerFlowAPI | PlatformControllerFlowAPI) {
  const locale = getLocale(flowAPI);
  const numberFormatter = new Intl.NumberFormat(locale);

  return (value: number) => {
    return numberFormatter.format(value);
  };
}
