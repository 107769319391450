import { LoyaltyProgram, ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';

interface Params {
  loyaltyProgram: LoyaltyProgram;
  isViewer: boolean;
  isSiteTemplate: boolean;
}

export const isProgramAvailable = ({ loyaltyProgram, isSiteTemplate, isViewer }: Params) => {
  const isProgramActive = loyaltyProgram.status === ProgramStatus.ACTIVE;
  const hasPremium = !!loyaltyProgram.premiumFeatures?.loyaltyProgram;

  if (isSiteTemplate) {
    return true;
  }
  if (!isViewer) {
    return true;
  }
  if (isProgramActive && hasPremium) {
    return true;
  }

  return false;
};
