import { updateMember } from '@wix/ambassador-members-v1-member/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const updateMemberBirthday = async (birthday: string, flowAPI: ControllerFlowAPI) => {
  const { httpClient } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const memberId = wixCodeApi.user.currentUser.id;

  await httpClient.request(
    updateMember({
      member: {
        id: memberId,
        contact: {
          birthdate: birthday,
        },
      },
    }),
  );
};
