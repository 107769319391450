import {
  BreakpointDesktop as BreakpointDesktopIcon,
  BreakpointEqualsMobile as BreakpointEqualsMobileIcon,
  BreakpointMobile as BreakpointMobileIcon,
  BreakpointToMobile as BreakpointToMobileIcon,
} from '@wix/wix-ui-icons-common';

export enum BreakpointsScope {
  All = 'all',
  Cascades = 'cascades',
  DesktopAndTablet = 'desktopAndTablet',
  Mobile = 'mobile',
}

export const iconByBreakpointsScopeMap: Record<BreakpointsScope, React.ComponentType> = {
  [BreakpointsScope.All]: BreakpointEqualsMobileIcon,
  [BreakpointsScope.Cascades]: BreakpointToMobileIcon,
  [BreakpointsScope.DesktopAndTablet]: BreakpointDesktopIcon,
  [BreakpointsScope.Mobile]: BreakpointMobileIcon,
};
