import { createFollowedChannel } from '@wix/ambassador-loyalty-socialmedia-v1-followed-channel/http';
import { Type as BackendChannelType } from '@wix/ambassador-loyalty-socialmedia-v1-followed-channel/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const followChannel = async (type: string, flowAPI: ControllerFlowAPI) => {
  const { httpClient } = flowAPI;

  await httpClient.request(
    createFollowedChannel({
      followedChannel: {
        channel: type as BackendChannelType,
      },
    }),
  );
};
